

$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-dark: #333333;
$color-med: #707070;
$color-light: #E3E3E3;


$primary-font: "proxima-nova", sans-serif;
$header-font: "archivo-black",  sans-serif;