@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.min";


html {
	background: $color-light;

	overflow-x: hidden;
	max-width: 100%;
	font-size: 16px;
	line-height: 1;

	// @include desktop-sm-down {
	// 	font-size: 15px;
	// }

	// @include tablet-down {
	// 	font-size: 14px;
	// }

	// @include phone-down {
	// 	font-size: 13px;
	// }

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-dark;
	font-family: $primary-font;
	font-size: rem(16);
	overflow-x: hidden;
	max-width: 100%;
}

a {
	color: $color-dark;
	transition: .3s all;

	img {
		transition: .3s all;
	}

	@include hover-focus {
		color: $color-light;

		img {
			opacity: .8;
		}
	}
}

// CAROUSEL
.owl-custom-nav-prev,
.owl-custom-nav-next {
	position: absolute;
	top: 105%;
	font-size: rem(40);

	i{
		color: $color-light;
	}
}

.owl-custom-nav-prev {
	left: 43%;

	@media screen and (max-width: 900px) {
		left: 40%;
	}
}

.owl-custom-nav-next {
	right: 43%;

	@media screen and (max-width: 900px) {
		right: 40%;
	}
}

.btn {
	background-color: $color-light;
	color: $color-dark;
	border-width: 0;
	font-size: rem(18);
	padding: rem(15) rem(50);
	display: inline-block;

	@include hover-focus {
		background-color: $color-dark;
	}
}

h2 {
	color: $color-light;
	font-size: rem(80);
	font-family: $header-font;
	padding: rem(130) 0 rem(30) rem(32);
	margin: 0;

	@media screen and (min-width:1100px) {
		font-size: rem(115);
		padding-right: 0;
	}
}

header {

	&.splash-bg {
		background-image: url('../img/header.png');
		background-size: cover;
		background-position: center;
		width: 100%;

		@media screen and (max-width:700px) {
			background-position: top;
		}
	}

	.container {
		max-width: rem(1600);
		display: flex;
		min-height: 100vh;

		align-items: start;
		justify-content: space-between;

		@media screen and (max-width:1100px) {
			flex-direction: column;
			align-items: center;
			color: $color-light;
			min-height: 80vh;
		}

		h1 {
			img {
				width: rem(450);

				@media screen and (max-width:700px) {
					width: rem(300);
					max-width: 100%;
				}
			}
		}

		.col {
			display: flex;
			align-items: center;
			padding-top: rem(56);

			@media screen and (max-width:700px) {
				flex-direction: column;
				text-align: center;
			}
		}

		.nav {
			list-style: none;
			font-size: rem(17);
			padding: 0;
			text-transform: uppercase;

			li {
				display: inline-block;
				margin: 0 2em;
				font-family: $header-font;
				font-size: rem(18);


				@media screen and (max-width: 1440px) {
					margin: 0 2em;
				}

				@media screen and (max-width: 1100px) {
					a {
						color: $color-light;
					}

					margin: 0 1em;
				}
			}
		}

		.social {
			list-style: none;
			font-size: rem(20);
			padding: 0;
			margin: 0 rem(15);

			@media screen and (max-width: 1100px) {
				filter: invert(1);
				padding-bottom: rem(16);
			}


			li {
				display: inline-block;
				vertical-align: middle;
				margin: .25em;
			}
		}
	}
}

.container {
	padding: 0;
}

main {
	background-image: url('../img/bg/dark-texture-bg.png');
	background-size: cover;
	background-position: center;
	background-color: $color-dark;

	#listen {
		padding: rem(1) 0 rem(128) 0;

		.owl-carousel {
			.item {
				display: flex;
				flex-direction: column;
				align-items: center;

				p {
					color: $color-light;
					font-size: rem(23);
					padding: rem(23);
					margin: 0;
				}
			}
		}

	}

	#video {
		@media screen and (max-width: 1100px) {
			margin-bottom: rem(120);
		}

		.container {
			padding: 0 rem(10);
		}
		p {
			color: $color-light;
			font-size: rem(23);

			@media screen and (max-width: 699px) {
				text-align: center;
			}
		}

		.embed-container {
			margin-top: rem(35.65);
			margin-bottom: rem(35.65);
		}
	}

	#tour {

		@media screen and (min-width:700px) {
			position: relative;
			height: rem(1300);
			margin: rem(146) 0 0 0;
		}

		.promo {
			height: rems(800);

			@media screen and (min-width:700px) {
				position: absolute;
				left: rem(-150);
				top: 0;
			}
		}

		.date-container {
			width: 100%;
			background-color: $color-light;
			padding: rem(50) rem(50) rem(120);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			position: relative;

			@media screen and (min-width:700px) {
				width: rem(804);
				z-index: 2;
				position: absolute;
				right: rem(-150);
				bottom: 0;
				min-height: rem(800);
			}

			@media screen and (min-width:1100px) {
				flex-direction: row;
				padding: rem(117);

				&::after {
					content: '';
				}
			}

			.vertical-header {
				align-self: flex-start;
				color: $color-dark;
				font-family: $header-font;
				font-size: rem(60);

				@media screen and (min-width:1100px) {
					font-size: rem(200);
					writing-mode: vertical-lr;
					transform: rotate(180deg);
					transform-origin: center;
					padding-bottom: rem(35);
				}
			}

			.tour-wrapper {
				max-height: rem(950);
				overflow: auto;

				&::-webkit-scrollbar {
					background-color: transparent;
					width: rem(10);
				}

				&::-webkit-scrollbar-thumb {
					width: rem(5);
					background-color: grey;
				}

				@media screen and (min-width:1100px) {
					padding-right: rem(100);
				}

				@media screen and (min-width:800px) {
				}

				.dates-btn {
					background-color: $color-dark;
					color: $color-light;
					border-width: 0;
					font-size: rem(18);
					padding: rem(15) rem(50);
					display: inline-block;
					position: absolute;
					bottom: rem(40);

					@include hover-focus {
						background-color: $color-light;
						color: $color-dark;
					}
				}

				#tour-dates {
					font-family: $primary-font;
					font-size: 18;

					.event-group {
						padding: rem(4) 0;
					}

					.event-date {
						padding: rem(4) 0;
					}

					.event-location {
						padding: rem(4) 0;
					}

					ß .event-venue {
						padding: rem(4) 0;
					}

					.event-links {
						font-weight: bold;
					}

					.link {
						color: $color-dark;
						font-size: rem(18);
					}

					hr {
						margin: rem(16) 0;
						background-color: $color-dark;
						height: rem(1.5);
						width: rem(100);
						margin-left: 0;
						margin-right: auto;
					}
				}
			}
		}
	}

	#shop {
		// padding: rem(1) 0 rem(249) 0;
		padding: rem(80) 0;

		.owl-carousel {
			.item {
				display: flex;
				flex-direction: column;
				align-items: center;

				img {}

				p {
					color: $color-light;
					font-size: rem(23);
					padding: rem(23);
				}
			}
		}

		.button-container {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}

}

footer {
	.footer-links {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.footer-logo {
		padding: rem(32);
	}

	.social {
		list-style: none;
		margin-bottom: 5rem;
		padding: 0;

		li {
			display: inline-block;
			vertical-align: middle;
			margin: .25em;
		}
	}

	.copyright {
		font-size: rem(12);
		text-align: center;
		text-transform: uppercase;
	}
}